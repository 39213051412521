import React, { Component } from "react"
import BookingWrapper from "../components/booking-screen/booking-wrapper"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Gallery from "react-grid-gallery"
import { GalleryContainer } from "../style/containers"
class TheEstatePage extends Component {
  state = {}
  render() {
    return (
      <Layout max>
        <SEO title="Book The Estate" />
        {/* <GalleryContainer>
          <Gallery maxRows={2} margin={2} images={IMAGES} />
        </GalleryContainer> */}
        <BookingWrapper title="The Estate" />
      </Layout>
    )
  }
}

export default TheEstatePage

const IMAGES = [
  {
    src: require("../images/aerial.jpg"),
    thumbnail: require("../images/aerial.jpg"),
    thumbnailWidth: 1300,
    thumbnailHeight: 1625,
  },
  {
    src: require("../images/table.jpg"),
    thumbnail: require("../images/table.jpg"),
    thumbnailWidth: 2000,
    thumbnailHeight: 1333,
  },

  {
    src: require("../images/table-2.jpg"),
    thumbnail: require("../images/table-2.jpg"),
    thumbnailWidth: 2000,
    thumbnailHeight: 1333,
  },
  {
    src: require("../images/flowers.jpg"),
    thumbnail: require("../images/flowers.jpg"),
    thumbnailWidth: 2000,
    thumbnailHeight: 1333,
  },
  {
    src: require("../images/dining.jpg"),
    thumbnail: require("../images/dining.jpg"),
    thumbnailWidth: 934,
    thumbnailHeight: 1401,
  },
]
