import styled from "styled-components"

export const GalleryContainer = styled.div`
  /* display: block; */
  margin-bottom: 30px;
  width: 100vw;
  z-index: -1;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  height: 300px;
`
